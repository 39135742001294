import Button from "@mui/material/Button";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid, Icon } from "tabler-react";
import { validationService } from "../../../../services";
import {
  createParameterGroup,
  getParameterGroup,
  updateParameterGroup,
} from "../../../../services/parameter-group-service";
import { SearchableSelect } from "../../../form-controls/searchable-select";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
export function ParameterGroupModal(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useState({
    name: "",
    description: "",
    nameError: "",
  });

  const [selectedParameters, setSelectedParameters] = useState([]);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  useEffect(() => {
    if (!_.isEmpty(props.parameters)) {
      if (props.parameterGroupId) {
        getParameterGroup(props.parameterGroupId)
          .then((res) => {
            setFormState({
              name: res.data.name,
              description: res.data.description,
              nameError: "",
            });
            setSelectedParameters(
              props.parameters.filter((x) =>
                res.data.parameters.map((a) => a.id).includes(x.id)
              )
            );
            setIsLoading(false);
          })
          .catch((err) => {
            alertify.error(err.response.data.message);
          });
      } else {
        setIsLoading(false);
      }
    }
  }, [props.parameterGroupId, props.parameters]);

  const handleSave = () => {
    if (!validateDataForSave()) {
      return;
    }
    formState.parameters = selectedParameters;
    if (props.parameterGroupId) {
      updateParameterGroup(props.parameterGroupId, formState)
        .then((res) => {
          alertify.success("Parameter Group updated.");
          props.handleModalClose();
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    } else {
      createParameterGroup(formState)
        .then((res) => {
          alertify.success("Parameter Group added.");
          props.handleModalClose();
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );
    if (_.isEmpty(selectedParameters)) {
      newState.selectedParametersError = "One or more parameter is required.";
    } else {
      newState.selectedParametersError = "";
    }

    var errorState = ["nameError", "selectedParametersError"];

    isFormValid = !validationService.hasError(newState, ...errorState);

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };
  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Dimmer active={isLoading} loader>
          <Card.Title>
            {props.parameterGroupId
              ? "Edit Parameter Group"
              : "Add Parameter Group"}

            <Icon
              name="x"
              onClick={props.handleModalClose}
              className="float-right pointer"
            ></Icon>
          </Card.Title>

          <Grid.Row alignItems="center">
            <Grid.Col width={12}>
              <Form.Group label={"Name"} isRequired>
                <Form.Input
                  type="text"
                  value={formState.name}
                  name="name"
                  onChange={handleInputChange}
                  error={formState.nameError}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={12}>
              <Form.Group label={"Description"}>
                <LynxTextArea
                  type="text"
                  value={formState.description}
                  name="description"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={12}>
              {" "}
              <Form.Group isRequired label={"Parameters"}>
                <SearchableSelect
                  name={`selectedParameters`}
                  error={formState.selectedParametersError}
                  options={props.parameters}
                  multiple
                  labelField="name"
                  idField="id"
                  id="parameterId"
                  value={selectedParameters}
                  placeholder={"Search or choose from list"}
                  onChange={(e) => {
                    setSelectedParameters(e);
                  }}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        <Button
          onClick={handleSave}
          variant="contained"
          className="float-right"
        >
          {props.parameterGroupId ? "Save" : "Add"}
        </Button>
      </Card.Footer>
    </Form>
  );
}
