import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useRef, useState } from "react";

import AudioFileIcon from "@mui/icons-material/AudioFile";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LockIcon from "@mui/icons-material/Lock";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import alertify from "alertifyjs";
import { saveAs } from "file-saver";
import _ from "lodash";
import { Dimmer } from "tabler-react";
import {
  addEntityAttachments,
  deleteEntityAttachment,
  downloadAttachment,
  downloadThumbnail,
  getEntityAttachments,
  updateAttachmentDesciption,
  validateFileSize,
} from "../../../services/attachments";
import { LynxDialog } from "./../../lynx-dialog";
import { Form, Grid } from "tabler-react";
import Box from "@mui/material/Box";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";

export function EventAttachmentDetails(props) {
  const [attachments, setAttachments] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [loading, setLoading] = useState(true);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [attachmentsUploading, setAttachmentsUploading] = useState(false);
  const [attachmentDescription, setAttachmentDescription] = useState("");

  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (!_.isEmpty(props.event)) {
      setThumbnails([]);
      getEntityAttachments("event", props.event.id).then((res) => {
        prepAndSetExistingFiles(res.data);
        setAttachments(res.data);
        setLoading(false);
      });
    }
  }, [props.event]);

  const handleInputClick = (event) => {
    event.target.value = "";
  };

  const prepAndSetExistingFiles = (files) => {
    var newFiles = [...files];

    newFiles
      .filter(
        (x) =>
          x.thumbnailPath &&
          !thumbnails
            .map((y) => y.entityAttachmentId)
            .includes(x.entityAttachmentId)
      )
      .forEach((file, i, array) => {
        downloadThumbnail(file.entityAttachmentId).then((res) => {
          setThumbnails((existing) => {
            return [
              {
                fileName: file.fileName,
                thumbnail: res.data,
                entityAttachmentId: file.entityAttachmentId,
                description: file.description,
              },
              ...existing,
            ];
          });
        });
      });
  };

  const handleViewAttachment = (file) => {
    setSelectedFile(file);
    setShowAttachmentDialog(true);
    setAttachmentDescription(file.description);
  };

  const handleDeleteAttachment = () => {
    deleteEntityAttachment(selectedFile.entityAttachmentId).then((res) => {
      if (selectedFile.thumbnail) {
        let newThumbnails = thumbnails.filter(
          (x) => x.entityAttachmentId != selectedFile.entityAttachmentId
        );
        setThumbnails(newThumbnails);
      } else {
        let newAttachments = attachments.filter(
          (x) => x.entityAttachmentId != selectedFile.entityAttachmentId
        );
        setAttachments(newAttachments);
      }
      alertify.success("Attachment Deleted");
    });
    setShowAttachmentDialog(false);
  };

  const handleDownloadFile = (id, fileName) => {
    downloadAttachment(id).then((res) => {
      saveAs(res.data, fileName);
    });
  };

  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    if (!_.isEmpty(selectedFiles)) {
      if (validateFileSize([...selectedFiles])) {
        setAttachmentsUploading(true);
        addEntityAttachments("event", props.event.id, selectedFiles)
          .then((res) => {
            getEntityAttachments("event", props.event.id).then((res) => {
              setTimeout(() => {
                setAttachmentsUploading(false);
                prepAndSetExistingFiles(res.data);
                setAttachments(res.data);
              }, 5000);
            });
          })
          .catch(() => {
            alertify.error("Error uploading attachments");
            setAttachmentsUploading(false);
          });
      } else {
        setShowFileSizeError(true);
      }
    }
  };

  const handleSaveDescription = () => {
    const dto = {
      description: attachmentDescription,
    };
    updateAttachmentDesciption(selectedFile.entityAttachmentId, dto).then(
      (res) => {
        alertify.success("Attachment Description Updated");
        const newThumbnails = [...thumbnails];
        const index = newThumbnails.findIndex(
          (f) => f.entityAttachmentId == selectedFile.entityAttachmentId
        );
        if (index != -1) {
          newThumbnails[index].description = attachmentDescription;
          setThumbnails(newThumbnails);
        }
      }
    );
  };

  const getFileIcon = (file) => {
    if (file.contentType.includes("video")) {
      return (
        <VideoFileIcon color="action" className="mb-5" sx={{ fontSize: 150 }} />
      );
    }
    if (file.contentType.includes("audio")) {
      return (
        <AudioFileIcon color="action" className="mb-5" sx={{ fontSize: 150 }} />
      );
    }
    return (
      <InsertDriveFileIcon
        color="action"
        className="mb-5"
        sx={{ fontSize: 150 }}
      />
    );
  };

  return (
    <div className="w-100  d-flex justify-content-center">
      <Card
        className="lynx-card"
        variant="outlined"
        sx={{ width: "100%", minHeight: loading ? "283px" : "" }}
      >
        <CardHeader
          className="lynx-card-header"
          title="Attachments"
          action={
            props.eventLocked ? (
              <LockIcon
                className="mt-3 mr-3"
                color="disabled"
                fontSize="small"
              />
            ) : (
              <Button onClick={handleUploadClick} disabled={props.eventLocked}>
                Upload
              </Button>
            )
          }
        />{" "}
        <CardContent className="pb-0">
          <Dimmer active={loading} loader>
            {_.isEmpty(attachments) && <div>No attachments</div>}
            <ImageList cols={3} gap={8} rowHeight={150}>
              {thumbnails.map((file, i) => (
                <>
                  <ImageListItem
                    key={"list" + _.toString(file.entityAttachmentId)}
                  >
                    <img
                      alt="attachment"
                      src={`${URL.createObjectURL(file.thumbnail)}`}
                      loading="lazy"
                      style={{ objectFit: "cover", height: 100 }}
                      key={"img" + _.toString(file.entityAttachmentId)}
                    />

                    <ImageListItemBar
                      title={file.fileName}
                      key={"item" + _.toString(file.entityAttachmentId)}
                      actionIcon={
                        <IconButton
                          key={"icon" + _.toString(file.entityAttachmentId)}
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${file.fileName}`}
                          onClick={() => handleViewAttachment(file)}
                        >
                          <InfoIcon
                            key={"info" + _.toString(file.entityAttachmentId)}
                          />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                </>
              ))}
              {!_.isEmpty(attachments) &&
                attachments
                  .filter((x) => x.thumbnailPath == null)
                  .map((file, i) => (
                    <>
                      <ImageListItem
                        key={"list" + _.toString(file.entityAttachmentId)}
                        sx={{ border: "1px solid black" }}
                      >
                        <div className=" h-100 d-flex align-items-center justify-content-center">
                          {getFileIcon(file)}
                        </div>

                        <ImageListItemBar
                          title={file.fileName}
                          sx={{ backgroundColor: "black" }}
                          key={"item" + _.toString(file.entityAttachmentId)}
                          actionIcon={
                            <IconButton
                              key={"icon" + _.toString(file.entityAttachmentId)}
                              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                              aria-label={`info about ${file.fileName}`}
                              onClick={() => handleViewAttachment(file)}
                            >
                              <InfoIcon
                                key={
                                  "info" + _.toString(file.entityAttachmentId)
                                }
                              />
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                    </>
                  ))}
            </ImageList>
          </Dimmer>
        </CardContent>{" "}
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
          onClick={handleInputClick}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                    text/plain, image/*, text/html, video/*, audio/*, .pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    .doc, .docx"
        />
      </Card>
      {showAttachmentDialog && !_.isEmpty(selectedFile) && (
        <LynxDialog
          open={showAttachmentDialog && !_.isEmpty(selectedFile)}
          handleClose={() => setShowAttachmentDialog(false)}
          handleDownload={() =>
            handleDownloadFile(
              selectedFile.entityAttachmentId,
              selectedFile.fileName
            )
          }
          handleDelete={handleDeleteAttachment}
          title={selectedFile.fileName}
          isCloseInHeader
          description={
            <Box sx={{ minWidth: 500 }}>
              <div className="mb-3">
                {selectedFile.thumbnail != null ? (
                  <img
                    src={`${URL.createObjectURL(selectedFile.thumbnail)}`}
                    loading="lazy"
                    key={"img" + _.toString(selectedFile.entityAttachmentId)}
                  />
                ) : (
                  getFileIcon(selectedFile)
                )}
              </div>
              <Box>
                <Form.Group label="Description">
                  <LynxTextArea
                    name="contactDetails"
                    onChange={(e) => setAttachmentDescription(e.target.value)}
                    value={attachmentDescription}
                  ></LynxTextArea>
                </Form.Group>
              </Box>
            </Box>
          }
          handleSave={handleSaveDescription}
        />
      )}
      <LynxDialog
        open={showFileSizeError}
        handleClose={() => setShowFileSizeError(false)}
        title={`File size exceeded`}
        description={
          "Attachments are over the required total size limit of 50MB."
        }
        handleConfirm={() => setShowFileSizeError(false)}
      />
      <LynxDialog
        open={attachmentsUploading}
        title={`Uploading Attachments. Do not close the window.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
    </div>
  );
}
