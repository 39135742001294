import Button from "@mui/material/Button";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { updateIncidentUser } from "../../../services/incident-service";
import CircularProgress from "@mui/material/CircularProgress";
import "./incident-form.css";
import { Box, IconButton, Stack } from "@mui/material";
import { LynxDialog } from "../../lynx-dialog";
import SingleSelect from "components/form-controls/single-select";
import { MultiSelect } from "components/form-controls/multi-select";
import { useSelector } from "react-redux";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { IncidentUserTypes, UserRoles } from "types/enums";
import { roleMatch } from "actions/auth";

export function IncidentInvestigationTeam(props) {
  const { users } = useSelector((state) => state.lookups);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLead, setSelectedLead] = useState(-1);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const { isLocked, toggleLock, incident, resetIsLocked } = props;

  useEffect(() => {
    if (users) {
      setSelectedLead(incident.investigationLeadUserId);
      if (
        incident.incidentUsers.find(
          (f) => f.type == IncidentUserTypes.InvestigationTeamMember
        )
      ) {
        const selectedUsers = incident.incidentUsers
          .filter((f) => f.type == IncidentUserTypes.InvestigationTeamMember)
          .map((m) => {
            return users.find((f) => f.id == m.userId);
          });

        setSelectedTeamMembers(selectedUsers);
      }
      setIsLoading(false);
    }
  }, [incident, users]);

  const onSaveInvestigationTeam = () => {
    setIsSaving(true);
    const payload = {
      investigationLeadUserId: selectedLead,
      incidentUsers: selectedTeamMembers.map((m) => ({
        incidentId: incident.id,
        userId: m.id,
      })),
    };

    updateIncidentUser(incident.id, payload)
      .then((res) => {
        alertify.success("Investigation team updated.");
        props.onUpdateInvestigationTeam(res.data);
        resetIsLocked();
      })
      .finally(() => {
        setIsSaving(false);
        setIsWarningOpen(false);
      });
  };

  const checkIsTeamChanged = () => {
    return (
      incident.investigationLeadUserId != selectedLead ||
      !_.isEqual(
        incident.incidentUsers
          .filter((f) => f.type == IncidentUserTypes.InvestigationTeamMember)
          .map((m) => m.userId),
        selectedTeamMembers.map((m) => m.id)
      )
    );
  };

  const getWarningMessage = () => {
    const currentUsers = [
      incident.incidentUsers.some(
        (s) => s.userId == incident.investigationLeadUserId
      )
        ? -1
        : incident.investigationLeadUserId || -1,
      ...incident.incidentUsers.map((m) => m.userId),
    ].filter((f) => f != -1);
    const selectedUsers = [
      selectedTeamMembers.some((s) => s.id == selectedLead)
        ? -1
        : selectedLead || -1,
      ...selectedTeamMembers.map((m) => m.id),
    ].filter((f) => f != -1);

    const addedUsers = [...new Set(selectedUsers)].filter(
      (item) => !currentUsers.some((s) => s == item)
    );

    const addedUserNames =
      addedUsers?.map((m) => users.find((f) => f.id == m)?.fullName) || [];

    return addedUsers.length > 0
      ? `Are you sure you want to add user${
          addedUsers.length > 1 ? "s" : ""
        } (${addedUserNames.join(
          ", "
        )}) to the investigation team? They will have access to view all incident and investigation details.`
      : "Are you sure you want to change the investigation team? The users may lose access to the investigation.";
  };

  return (
    <Box>
      <Form className="card mb-5">
        <Dimmer active={isLoading} loader>
          <Card.Header>
            <Card.Title>
              <Stack direction="row" alignItems="center">
                <span>Investigation Team</span>
                {roleMatch([UserRoles.Admin]) &&
                  incident.status.toLowerCase() == "closed" && (
                    <IconButton
                      aria-label="Lock"
                      onClick={() => toggleLock("investigationTeam")}
                      title={isLocked ? "Unlock" : "Lock"}
                    >
                      {isLocked ? (
                        <Lock />
                      ) : (
                        <LockOpenOutlined color="success" />
                      )}
                    </IconButton>
                  )}
              </Stack>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Investigation Lead">
                  {/* {users.length > 0 && selectedLead && ( */}
                  <SingleSelect
                    onChange={(e) => setSelectedLead(e.target.value)}
                    dropdownValues={users}
                    label="fullName"
                    id="id"
                    name="investigationLeadUserId"
                    value={selectedLead}
                    disabled={isLocked}
                  />
                  {/* )} */}
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <Form.Group label="Investigation Team Members">
                  <MultiSelect
                    name="investigationTeam"
                    id="investigationTeam"
                    onChange={() => {}}
                    value={selectedTeamMembers}
                    dropdownValues={users.filter((f) => f.id != selectedLead)}
                    onChange={(e) =>
                      setSelectedTeamMembers(e.target.value || [])
                    }
                    key="id"
                    label="fullName"
                    disabled={isLocked}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Dimmer>
        <Card.Footer>
          <Button
            variant="contained"
            className="float-right"
            onClick={() => {
              if (checkIsTeamChanged()) {
                setIsWarningOpen(true);
              } else {
                onSaveInvestigationTeam();
              }
            }}
            disabled={isLocked}
          >
            Save
          </Button>
        </Card.Footer>
      </Form>
      {isSaving && (
        <LynxDialog
          open={isSaving}
          title="Saving investigation team. Do not close the window."
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}

      <LynxDialog
        title={`Warning`}
        description={getWarningMessage()}
        open={isWarningOpen}
        handleClose={() => setIsWarningOpen(false)}
        buttons={() => {
          return (
            <Button
              variant="contained"
              color="success"
              onClick={onSaveInvestigationTeam}
            >
              Yes
            </Button>
          );
        }}
      />
    </Box>
  );
}
