import React, { useEffect, useRef, useState } from "react";

import AudioFileIcon from "@mui/icons-material/AudioFile";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import alertify from "alertifyjs";
import { saveAs } from "file-saver";
import _ from "lodash";
import { Card, Form, Grid } from "tabler-react";
import { dateUtil } from "../services/date-util";
import Box from "@mui/material/Box";
import {
  addEntityAttachments,
  deleteEntityAttachment,
  downloadAttachment,
  downloadThumbnail,
  getEntityAttachments,
  updateAttachmentDesciption,
  validateFileSize,
} from "./../services/attachments";
import { LynxDialog } from "./lynx-dialog";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { LynxTextArea } from "./form-controls/lynx-form-controls";
export function AttachmentViewer(props) {
  const [files, setFiles] = useState([]);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [entityAttachments, setEntityAttachments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [attachmentsUploading, setAttachmentsUploading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const hiddenFileInput = useRef(null);
  const dimensions = useWindowDimensions();
  const [attachmentDescription, setAttachmentDescription] = useState("");

  useEffect(() => {
    if (props.isExistingEntity && props.entityType && props.entityId) {
      getEntityAttachments(props.entityType, props.entityId).then((res) => {
        setEntityAttachments(res.data);
      });
    }
  }, [props.isExistingEntity, props.entityType, props.entityId]);

  useEffect(() => {
    if (!_.isEmpty(uploadedFiles)) {
      let newFiles = [];
      uploadedFiles.forEach((file) => {
        const fileUrl = URL.createObjectURL(file);
        newFiles.push({
          name: file.name,
          url: fileUrl,
          contentType: file.type,
          lastModified: file.lastModified,
        });
        return () => URL.revokeObjectURL(fileUrl);
      });
      setFiles([...newFiles]);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (!_.isEmpty(entityAttachments)) {
      prepAndSetExistingFiles(entityAttachments);
    }
  }, [entityAttachments]);
  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (validateFileSize([...uploadedFiles, ...selectedFiles])) {
      if (!props.isExistingEntity) {
        var newFiles = [...uploadedFiles, ...selectedFiles];
        setUploadedFiles(newFiles);
        props.handleSetUploadedFiles(newFiles);
      } else {
        setAttachmentsUploading(true);
        addEntityAttachments(props.entityType, props.entityId, selectedFiles)
          .then((res) => {
            getEntityAttachments(props.entityType, props.entityId).then(
              (res) => {
                setTimeout(() => {
                  setAttachmentsUploading(false);
                  prepAndSetExistingFiles(
                    res.data.filter(
                      (x) =>
                        !files
                          .map((a) => a.entityAttachmentId)
                          .includes(x.entityAttachmentId)
                    )
                  );
                }, 5000);
              }
            );
            props.onUpload && props.onUpload();
          })
          .catch(() => {
            alertify.error("Error uploading attachments");
            setAttachmentsUploading(false);
          });
      }
    } else {
      setShowFileSizeError(true);
    }
  };

  const prepAndSetExistingFiles = (entityAttachments) => {
    var newFiles = [...entityAttachments];

    newFiles.forEach((file, i, array) => {
      if (file.thumbnailPath) {
        downloadThumbnail(file.entityAttachmentId)
          .then((res) => {
            const fileUrl = URL.createObjectURL(res.data);
            setFiles((existing) => {
              return [
                {
                  name: file.fileName,
                  url: fileUrl,
                  contentType: file.contentType,
                  entityAttachmentId: file.entityAttachmentId,
                  createdByUserFullName: file.createdByUserFullName,
                  createdDateTimeUtc: file.createdDateTimeUtc,
                  description: file.description,
                },
                ...existing,
              ];
            });
            return () => URL.revokeObjectURL(fileUrl);
          })
          .catch(() => {
            setFiles((existing) => {
              return [
                {
                  name: file.fileName,

                  contentType: file.contentType,
                  entityAttachmentId: file.entityAttachmentId,
                  createdByUserFullName: file.createdByUserFullName,
                  createdDateTimeUtc: file.createdDateTimeUtc,
                  description: file.description,
                },
                ...existing,
              ];
            });
          });
      } else {
        setFiles((existing) => {
          return [
            {
              name: file.fileName,
              contentType: file.contentType,
              entityAttachmentId: file.entityAttachmentId,
              createdByUserFullName: file.createdByUserFullName,
              createdDateTimeUtc: file.createdDateTimeUtc,
              description: file.description,
            },
            ...existing,
          ];
        });
      }
    });
  };

  const handleDownloadFile = (id, fileName) => {
    downloadAttachment(id).then((res) => {
      saveAs(res.data, fileName);
    });
  };
  const handleInputClick = (event) => {
    event.target.value = "";
  };

  const handleViewAttachment = (file) => {
    setSelectedFile(file);
    setShowAttachmentDialog(true);
    setAttachmentDescription(file.description);
  };

  const handleDeleteAttachment = () => {
    if (props.isExistingEntity) {
      deleteEntityAttachment(selectedFile.entityAttachmentId).then((res) => {
        let newFiles = files.filter(
          (x) => x.entityAttachmentId != selectedFile.entityAttachmentId
        );
        setFiles(newFiles);
      });
    } else {
      let newFiles = files.filter(
        (x) =>
          x.name != selectedFile.name &&
          x.lastModified != selectedFile.lastModified
      );

      setFiles(newFiles);
      props.handleSetUploadedFiles(
        uploadedFiles.filter(
          (x) =>
            x.name != selectedFile.name &&
            x.lastModified != selectedFile.lastModified
        )
      );
    }
    alertify.success("Attachment Deleted");
    setShowDelete(false);
    setShowAttachmentDialog(false);
  };

  const handleSaveDescription = () => {
    const dto = {
      description: attachmentDescription,
    };
    updateAttachmentDesciption(selectedFile.entityAttachmentId, dto).then(
      (res) => {
        alertify.success("Attachment Description Updated");
        const newThumbnails = [...files];
        const index = newThumbnails.findIndex(
          (f) => f.entityAttachmentId == selectedFile.entityAttachmentId
        );
        if (index != -1) {
          newThumbnails[index].description = attachmentDescription;
          setFiles(newThumbnails);
        }
      }
    );
  };

  return (
    <Card className={props.cardClass ?? ""}>
      <Card.Header className="justify-content-between">
        <Card.Title>{props.title ?? "Attachments"}</Card.Title>
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
          onClick={handleInputClick}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
    text/plain, image/*, text/html, video/*, audio/*, .pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
    .doc, .docx"
        />
        <Button disabled={props.isLocked} onClick={handleUploadClick}>
          Upload
        </Button>
      </Card.Header>
      <Card.Body className={`${files.length <= 0 ? "" : "p-0"}`}>
        {files.length <= 0 && (
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <div>No attachments</div>
            </Grid.Col>
          </Grid.Row>
        )}
        <ImageList
          cols={dimensions.isMobile ? 2 : props.cols ?? 3}
          gap={8}
          rowHeight={props.rowHeight ?? 150}
        >
          {files.map((file, i) => (
            <>
              {file.contentType.includes("image") ? (
                <ImageListItem key={"list" + _.toString(i)}>
                  <img
                    alt={file.name}
                    src={file.url}
                    loading="lazy"
                    key={"img" + _.toString(i)}
                    style={{ objectFit: "cover", height: 100, minWidth: 150 }}
                  />

                  <ImageListItemBar
                    title={file.name}
                    key={
                      "item" + _.toString(file.entityAttachmentId || file.name)
                    }
                    actionIcon={
                      <IconButton
                        key={
                          "icon" +
                          _.toString(file.entityAttachmentId || file.name)
                        }
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${file.fileName}`}
                        onClick={() => handleViewAttachment(file)}
                      >
                        <InfoIcon
                          key={"info" + _.toString(file.entityAttachmentId)}
                        />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ) : (
                <ImageListItem
                  key={"list" + _.toString(i)}
                  className="w-100"
                  sx={{ border: "1px solid black" }}
                >
                  <div className=" h-100 w-100 d-flex align-items-center justify-content-center">
                    {getFileIcon(file)}
                  </div>

                  <ImageListItemBar
                    title={file.name}
                    key={
                      "item" + _.toString(file.entityAttachmentId || file.name)
                    }
                    actionIcon={
                      <IconButton
                        key={
                          "icon" +
                          _.toString(file.entityAttachmentId || file.name)
                        }
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${file.fileName}`}
                        onClick={() => handleViewAttachment(file)}
                      >
                        <InfoIcon
                          key={"info" + _.toString(file.entityAttachmentId)}
                        />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              )}
            </>
          ))}
          {showAttachmentDialog && !_.isEmpty(selectedFile) && (
            <LynxDialog
              open={showAttachmentDialog && !_.isEmpty(selectedFile)}
              handleClose={() => setShowAttachmentDialog(false)}
              handleDownload={() =>
                handleDownloadFile(
                  selectedFile.entityAttachmentId,
                  selectedFile.name
                )
              }
              handleDelete={() => setShowDelete(true)}
              disableDelete={props.disableDelete || props.isLocked}
              title={selectedFile.name}
              isCloseInHeader
              handleSave={
                !props.isLocked &&
                selectedFile.entityAttachmentId &&
                handleSaveDescription
              }
              description={
                <>
                  <div className="p-3">
                    {selectedFile.contentType.includes("image") &&
                    selectedFile.url ? (
                      <img
                        src={selectedFile.url}
                        alt={
                          "img" +
                          _.toString(
                            selectedFile.entityAttachmentId || selectedFile.name
                          )
                        }
                        loading="lazy"
                        key={
                          "img" +
                          _.toString(
                            selectedFile.entityAttachmentId || selectedFile.name
                          )
                        }
                      />
                    ) : (
                      getFileIcon(selectedFile)
                    )}
                  </div>
                  {selectedFile.entityAttachmentId && (
                    <Box>
                      <Form.Group label="Description">
                        <LynxTextArea
                          name="contactDetails"
                          onChange={(e) =>
                            setAttachmentDescription(e.target.value)
                          }
                          value={attachmentDescription}
                          disabled={props.isLocked}
                        ></LynxTextArea>
                      </Form.Group>
                    </Box>
                  )}

                  {selectedFile.createdByUserFullName && (
                    <>
                      <div>
                        Uploaded By: {selectedFile.createdByUserFullName}
                      </div>
                      <div>
                        {dateUtil.convertDateTimeToLocal(
                          selectedFile.createdDateTimeUtc
                        )}
                      </div>
                    </>
                  )}
                </>
              }
            />
          )}
        </ImageList>
      </Card.Body>
      <LynxDialog
        open={showFileSizeError}
        handleClose={() => setShowFileSizeError(false)}
        title={`File size exceeded`}
        description={
          "Attachments are over the required total size limit of 50MB."
        }
        handleConfirm={() => setShowFileSizeError(false)}
      />
      <LynxDialog
        open={attachmentsUploading}
        title={`Uploading Attachments. Do not close the window.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />

      <LynxDialog
        open={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={handleDeleteAttachment}
        title={`Delete Attachment?`}
        description={"The action can not be undone."}
      />
    </Card>
  );
}

const getFileIcon = (file) => {
  if (file.contentType.includes("video")) {
    return <VideoFileIcon color="action" sx={{ fontSize: 150 }} />;
  }
  if (file.contentType.includes("audio")) {
    return <AudioFileIcon color="action" sx={{ fontSize: 150 }} />;
  }
  return (
    <InsertDriveFileIcon
      color="action"
      className="mb-5"
      sx={{ fontSize: 150 }}
    />
  );
};
