import { roleMatch } from "actions/auth";
import { IncidentUserTypes, UserRoles } from "types/enums";

export function canSubmitStage(incidentUsers, userId, type) {
  if (roleMatch([UserRoles.Admin]) || roleMatch([UserRoles.InternalUser])) {
    return true;
  }
  if (
    incidentUsers &&
    incidentUsers.find((x) => x.userId == userId && x.type == type)
  ) {
    return true;
  }
  return false;
}
export function canSubmitStageAction(actionUsers, userId, type) {
  if (roleMatch([UserRoles.Admin]) || roleMatch([UserRoles.InternalUser])) {
    return true;
  }
  if (
    actionUsers &&
    actionUsers.find((x) => x.userId == userId && x.type == type)
  ) {
    return true;
  }
  return false;
}
export function canSubmitInvestigation(incident, userId) {
  if (roleMatch([UserRoles.Admin]) || roleMatch([UserRoles.InternalUser])) {
    return true;
  }

  if (
    canSubmitStage(
      incident.incidentUsers,
      userId,
      IncidentUserTypes.InvestigationTeamMember
    )
  ) {
    return true;
  }

  if (incident.investigationLeadUserId == userId) {
    return true;
  }
}
export function canViewInvestigation(incident, userId) {
  if (roleMatch([UserRoles.Admin]) || roleMatch([UserRoles.InternalUser])) {
    return true;
  }

  if (
    canSubmitStage(
      incident.incidentUsers,
      userId,
      IncidentUserTypes.InvestigationTeamMember
    ) ||
    canSubmitStage(
      incident.incidentUsers,
      userId,
      IncidentUserTypes.InitialReviewer
    ) ||
    canSubmitStage(
      incident.incidentUsers,
      userId,
      IncidentUserTypes.FinalReviewer
    )
  ) {
    return true;
  }

  if (incident.investigationLeadUserId == userId) {
    return true;
  }
}
