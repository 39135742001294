import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import localforage from "localforage";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { roleMatch } from "../../../actions/auth";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { validationService } from "../../../services";
import { getAssetLayerById, getAssetLookups } from "../../../services/assets";
import { addEntityAttachments } from "../../../services/attachments";
import {
  getCustomFields,
  setupInitialCustomFieldState,
  transformCustomFieldState,
} from "../../../services/custom-fields-service";
import { dateUtil } from "../../../services/date-util";
import {
  createEventWithAttachments,
  createGuid,
  deleteEvent,
  getEvent,
  patchEvent,
} from "../../../services/events";
import { getLookups } from "../../../services/lookup";
import {
  assetStore,
  categoryStore,
  customFieldStore,
  eventStore,
  eventSyncStore,
  subCategoryStore,
} from "../../../services/offlineDataService";
import { EntityTypes, EventStatuses, UserRoles } from "../../../types/enums";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { AttachmentViewer } from "../../attachment-viewer";
import { MultiSelect } from "../../form-controls/multi-select";
import { LocationSelectionControl } from "../../location-selection-control";
import { LynxDialog } from "../../lynx-dialog";
import "../events/event-modal.css";
import { ContactForm } from "../contacts/contact-form";
import { getPermits } from "./../../../services/permit-service";
import { Contacts } from "./contacts";
import { CustomField } from "./custom-field";
import { EventCorrespondenceMerge } from "./event-correspondence-merge";
import { EventDetailsCorrespondenceLog } from "./event-details-correspondence-log";
import { LinkedEvents } from "./linked-events";
import { Tags } from "./tags";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";

export const eventSyncLocalStorageName = "eventSync";

export function EventForm(props) {
  const location = useLocation();
  const history = useHistory();
  const isScheduledEvent = () => {
    if (props.isScheduledEvent) {
      return true;
    }
    const values = queryString.parse(location.search);
    if (values.isScheduled) {
      return true;
    }
    return false;
  };

  const initialForm = {
    assetId: "",
    eventDateTime: !isScheduledEvent()
      ? moment().format("YYYY-MM-DD HH:mm")
      : "",
    eventDueDateTime: "",
    eventCategoryId: "",
    eventSubCategoryId: "",
    isConfidential: false,
    description: "",
    actorName: "",
    actorDescription: "",
    numberOfInjuries: "",
    injuriesType: "",
    numberOfFatalities: "",
    causeOfFatalities: "",
    location: "",
    locationTypeId: "",
    severity: "",
    urgency: "",
    followUpRequired: false,
    followUpDate: "",
    followUpDescription: "",
    eventDateTimeError: "",
    assets: [],
    permits: [],
    eventCategoryIdError: "",
    eventSubCategoryIdError: "",
    assetIdError: "",
    locationError: "",
    locationTypeIdError: "",
    severityError: "",
    longitude: "",
    latitude: "",
    longitudeError: "",
    latitudeError: "",
    eventUrgencyError: "",
    eventDueDateTimeError: "",
    entityUsers: [],
  };
  const [reviewSaveWarning, setReviewSaveWarning] = useState(false);
  const [formState, setFormState] = useState({});
  const [initialFormState, setInitialFormState] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [assetLookups, setAssetLookups] = useState([]);
  const [selectedEventSubCategories, setSelectedEventSubCategories] = useState(
    []
  );
  const [corLogExpanded, setCorLogExpanded] = useState(true);
  const [eventCategories, setEventCategories] = useState([]);
  const [eventSubCategories, setEventSubCategories] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [eventCustomFields, setEventCustomFields] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [existingCustomFieldValues, setExistingCustomFieldValues] =
    useState(null);
  const [selectedAssetGeojson, setSelectedAssetGeojson] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [event, setEvent] = useState({});
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [locationTouched, setLocationTouched] = useState(false);

  const [allContacts, setAllContacts] = useState([]);

  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [customFieldsExpanded, setCustomFieldsExpanded] = useState(true);
  const [locationExpanded, setLocationExpanded] = useState(false);
  const [permits, setPermits] = useState([]);
  const [eventLocked, setEventLocked] = useState(false);
  const [parentEventId, setParentEventId] = useState(0);
  const [linkedEvents, setLinkedEvents] = useState([]);
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [subCategoriesLoading, setSubCategoriesLoading] = useState(true);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [defaultLatitude, setDefaultLatitude] = useState("");
  const [defaultLongitude, setDefaultLongitude] = useState("");
  const [customFieldState, setCustomFieldState] = useState({});
  const [showAddContact, setShowAddContact] = useState(false);
  const [showMergeEvent, setShowMergeEvent] = useState(false);
  const [eventContacts, setEventContacts] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [permitsLoading, setPermitsLoading] = useState(true);
  const isOffline = !useOnlineStatus();
  var organization = useSelector((state) => state.organization);
  const { users } = useSelector((state) => state.lookups);
  let urgencyList = [
    { id: "Green", value: "Low" },
    { id: "Yellow", value: "Medium" },
    { id: "Red", value: "High" },
  ];

  useEffect(() => {
    if (
      !assetsLoading &&
      !customFieldsLoading &&
      !categoriesLoading &&
      !subCategoriesLoading &&
      !permitsLoading &&
      !_.isEmpty(formState)
    ) {
      setIsLoading(false);
    }
  }, [
    assetsLoading,
    customFieldsLoading,
    categoriesLoading,
    subCategoriesLoading,
    formState,
  ]);

  useEffect(() => {}, [organization]);

  useEffect(() => {
    if (props.openSection == "details") {
      setDetailsExpanded(true);
      setCustomFieldsExpanded(false);
      setLocationExpanded(false);
    }
    if (props.openSection == "additional-fields") {
      setDetailsExpanded(false);
      setCustomFieldsExpanded(true);
      setLocationExpanded(false);
    }
    if (props.openSection == "location") {
      setDetailsExpanded(false);
      setCustomFieldsExpanded(false);
      setLocationExpanded(true);
    }
  }, [props.openSection]);

  useEffect(() => {
    if (!organization.isLoading) {
      loadAssetLookups();
      loadEventCustomFields();
      loadEventCategoryLookups();
      loadEventSubCategoryLookups();
      loadPermits();
    }
  }, [organization]);

  const loadPermits = () => {
    if (organization && organization.hasPermitFeature) {
      getPermits({ pageSize: 1000 })
        .then((res) => {
          setPermits(res.data);
          setPermitsLoading(false);
        })
        .catch(() => {
          setPermits([]);
          setPermitsLoading(false);
        });
    } else {
      setPermits([]);
      setPermitsLoading(false);
    }
  };

  useEffect(() => {
    if (!assetsLoading && !permitsLoading) {
      loadForm();
    }
  }, [assetsLoading, permitsLoading]);

  useEffect(() => {
    if (
      !_.isEmpty(props.defaultValues) &&
      props.defaultValues.assetId &&
      !_.isEmpty(assetLookups)
    ) {
      var asset = assetLookups.find((a) => a.id == props.defaultValues.assetId);
      if (asset.centroidLatitude != null && asset.centroidLongitude != null) {
        setDefaultLatitude(asset.centroidLatitude);
        setDefaultLatitude(asset.centroidLongitude);
        getAssetLayerGeojson(props.defaultValues.assetId);
      }
    }
  }, [props.defaultValues, assetLookups]);

  useEffect(() => {
    //populate custom fields on form when cat or sub cat changes
    if (!_.isEmpty(eventCustomFields)) {
      if (formState.eventSubCategoryId) {
        setSubCategoryCustomFields(formState.eventSubCategoryId);
      }
      if (formState.eventCategoryId && !formState.eventSubCategoryId) {
        setCategoryCustomFields(formState.eventCategoryId);
      }
      if (!formState.eventCategoryId && !formState.eventSubCategoryId) {
        setStandardCustomFields();
      }
    }
  }, [
    formState.eventCategoryId,
    formState.eventSubCategoryId,
    eventCustomFields,
  ]);

  useEffect(() => {
    //setup custom fields on initial form for new events
    if (props.entityId <= 0 && !_.isEmpty(eventCustomFields)) {
      let newCustomFieldState = setupInitialCustomFieldState(eventCustomFields);
      setCustomFieldState(newCustomFieldState);
    }

    if (
      props.entityId > 0 &&
      !_.isEmpty(eventCustomFields) &&
      existingCustomFieldValues
    ) {
      let newCustomFieldState = setupInitialCustomFieldState(
        eventCustomFields,
        existingCustomFieldValues
      );
      setCustomFieldState(newCustomFieldState);
    }
  }, [eventCustomFields, props.entityId, existingCustomFieldValues]);

  useEffect(() => {
    if (!_.isEmpty(event) && !_.isEmpty(eventSubCategories)) {
      setSelectedEventSubCategories(
        eventSubCategories.filter(
          (x) => x.parentLookupId == event.eventCategoryId
        )
      );
    }
  }, [event, eventSubCategories]);

  useEffect(() => {
    if (!_.isEmpty(event) && !_.isEmpty(users) && !organization.isLoading) {
      if (!organization.multipleAssetEvents) {
        setAvailableUsers(
          users.filter(
            (x) =>
              x.userLookups
                .filter((y) => y.lookupId == event.eventCategoryId)
                .map((x) => x.userId)
                .includes(x.id) &&
              x.userAssets
                .filter((y) => y.assetId == event.assetId)
                .map((a) => a.userId)
                .includes(x.id)
          )
        );
      } else {
        //don't enforce asset permissions for orgs with multiple asset events
        setAvailableUsers(
          users.filter((x) =>
            x.userLookups
              .filter((y) => y.lookupId == event.eventCategoryId)
              .map((x) => x.userId)
              .includes(x.id)
          )
        );
      }
    }
  }, [event, users, organization]);
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const loadAssetLookups = () => {
    getAssetLookups()
      .then((res) => {
        setAssetLookups(res.data);
        localforage.setItem(assetStore, res.data);
        setAssetsLoading(false);
      })
      .catch(() => {
        //if offline or error, load from indexDb.
        localforage.getItem(assetStore).then((data) => {
          setAssetLookups(data.sort((a, b) => a.name.localeCompare(b.name)));
          setAssetsLoading(false);
        });
      });
  };

  const loadEventCustomFields = () => {
    getCustomFields(EntityTypes.Event)
      .then((res) => {
        let customFields = res.data.filter((x) => !x.isDeleted);
        setEventCustomFields(customFields);
        localforage.setItem(customFieldStore, customFields);
        setCustomFieldsLoading(false);
      })
      .catch(() => {
        //if offline or error, load from indexDb.
        localforage.getItem(customFieldStore).then((data) => {
          setEventCustomFields(data);
          setCustomFieldsLoading(false);
        });
      });
  };

  const loadEventCategoryLookups = () => {
    getLookups("eventCategory")
      .then((res) => {
        setEventCategories(res.data);
        localforage.setItem(categoryStore, res.data);
        setCategoriesLoading(false);
      })
      .catch(() => {
        //if offline or error, load from indexDb.
        localforage.getItem(categoryStore).then((data) => {
          setEventCategories(data.sort((a, b) => a.code.localeCompare(b.code)));
          setCategoriesLoading(false);
        });
      });
  };

  const loadEventSubCategoryLookups = () => {
    getLookups("eventSubCategory")
      .then((res) => {
        setEventSubCategories(res.data);
        localforage.setItem(subCategoryStore, res.data);
        setSubCategoriesLoading(false);
      })
      .catch(() => {
        //if offline or error, load from indexDb.

        localforage.getItem(subCategoryStore).then((data) => {
          setEventSubCategories(
            data.sort((a, b) => a.code.localeCompare(b.code))
          );
          setSubCategoriesLoading(false);
        });
      });
  };

  const prepExistingEvent = (event) => {
    if (
      _.toLower(event.status) !== _.toLower(EventStatuses.PendingEmailReview)
    ) {
      setLocationTouched(true);
    }
    setEvent(event);
    setExistingCustomFieldValues(event.eventCustomFieldValues);
    setEventLocked(event.reviewCompleted);
    setFormStateFromProps(event);
    //Why is this being done? all subcategories are being loaded with the page

    if (
      (organization.multipleAssetEvents && _.last(event.entityAssets)) ||
      (!organization.multipleAssetEvents && event && event.assetId)
    ) {
      getAssetLayerGeojson(
        organization.multipleAssetEvents
          ? _.last(event.entityAssets).assetId
          : event.assetId
      );
    }

    setLinkedEvents(event.linkedEvents);
  };

  const loadForm = () => {
    if (props.entityId > 0) {
      if (props.event) {
        prepExistingEvent(props.event);
      } else {
        getEvent(props.entityId).then((res) => {
          prepExistingEvent(res.data);
        });
      }
    } else {
      let newState = { ...initialForm };
      if (!_.isEmpty(props.defaultValues)) {
        newState = { ...newState, ...props.defaultValues };
        setParentEventId(props.linkedEntityId);
      }

      setFormState(newState);
    }
  };

  const getAssetLayerGeojson = (assetId) => {
    getAssetLayerById(assetId).then((res) => {
      if (!_.isEmpty(res.data.geojson)) {
        setSelectedAssetGeojson(JSON.parse(res.data.geojson));
      }
    });
  };

  const setStandardCustomFields = () => {
    let newCustomFields = [
      ...eventCustomFields.filter((x) => x.parentLookupId == null),
    ];
    setSelectedCustomFields(newCustomFields);
  };

  const setCategoryCustomFields = (value) => {
    let newCustomFields = [
      ...eventCustomFields.filter((x) => x.parentLookupId == null),
    ];

    newCustomFields = [
      ...newCustomFields,
      ...eventCustomFields.filter(
        (x) => x.parentLookupId == value && x.childLookupId == null
      ),
    ];

    setSelectedCustomFields(newCustomFields);
  };

  const setSubCategoryCustomFields = (value) => {
    var customFields = _.cloneDeep(eventCustomFields);
    let newCustomFields = [
      ...customFields.filter(
        (x) =>
          x.parentLookupId == null ||
          (x.parentLookupId == formState.eventCategoryId &&
            x.childLookupId == null)
      ),
    ];
    if (value) {
      newCustomFields = [
        ...newCustomFields,
        ...eventCustomFields.filter((x) => x.childLookupId == value),
      ];
    }
    setSelectedCustomFields(newCustomFields);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value = eventCustomFields
      .filter((x) => x.fieldType == "Checkbox")
      .map((x) => `${x.fieldName}.booleanValue`)
      .includes(name)
      ? e.target.checked
      : name == "followUpRequired" ||
        name == "appliesToPermit" ||
        name == "isConfidential"
      ? e.target.checked
      : eventCustomFields
          .filter((x) => x.fieldType == "MultiSelect")
          .map((x) => `${x.fieldName}.textValue`)
          .includes(name)
      ? e.target.value.join("|")
      : e.target.value;

    if (name === "eventCategoryId") {
      if (_.isEmpty(value)) {
        setSelectedEventSubCategories([]);
        setAvailableUsers([]);
        newState.entityUsers = [];
      }

      if (value !== newState.eventCategoryId) {
        newState.eventSubCategoryId = "";
        newState.eventSubCategoryIdError = "";
        setSelectedEventSubCategories(
          eventSubCategories.filter((x) => x.parentLookupId == value)
        );
        if (newState.assetId && !organization.multipleAssetEvents) {
          let userPermissions = users.filter(
            (x) =>
              x.userLookups
                .filter((y) => y.lookupId == value)
                .map((x) => x.userId)
                .includes(x.id) &&
              x.userAssets
                .filter((y) => y.assetId == newState.assetId)
                .map((x) => x.userId)
                .includes(x.id)
          );

          var entityUsers = [];
          newState.entityUsers.forEach((atu) => {
            if (userPermissions.map((x) => x.id).includes(atu.id)) {
              entityUsers.push(atu);
            }
          });
          newState.entityUsers = [...entityUsers];
          setAvailableUsers(userPermissions);
        } else if (organization.multipleAssetEvents) {
          let userPermissions = users.filter((x) =>
            x.userLookups
              .filter((y) => y.lookupId == value)
              .map((x) => x.userId)
              .includes(x.id)
          );
          var entityUsers = [];
          newState.entityUsers.forEach((atu) => {
            if (userPermissions.map((x) => x.id).includes(atu.id)) {
              entityUsers.push(atu);
            }
          });
          newState.entityUsers = [...entityUsers];
          setAvailableUsers(userPermissions);
        }
      }
    }

    if (name === "assetId") {
      if (newState.eventCategoryId && !organization.multipleAssetEvents) {
        var userPermissions = users.filter(
          (x) =>
            x.userLookups
              .filter((y) => y.lookupId == newState.eventCategoryId)
              .map((x) => x.userId)
              .includes(x.id) &&
            x.userAssets
              .filter((y) => y.assetId == value)
              .map((x) => x.userId)
              .includes(x.id)
        );
        var entityUsers = [];
        newState.entityUsers.forEach((atu) => {
          if (userPermissions.map((x) => x.id).includes(atu.id)) {
            entityUsers.push(atu);
          }
        });
        newState.entityUsers = [...entityUsers];
        setAvailableUsers(userPermissions);
      }
    }
    if (name == "followUpRequired" && !value) {
      newState.followUpDate = "";
      newState.followUpDescription = "";
    }

    _.set(newState, name, value);

    setFormState(newState);
  };

  const handleCustomFieldChange = (e) => {
    let newState = { ...customFieldState };
    const name = e.target.name;
    const value = eventCustomFields
      .filter((x) => x.fieldType == "Checkbox")
      .map((x) => `${x.fieldName}.booleanValue`)
      .includes(name)
      ? e.target.checked
      : eventCustomFields
          .filter((x) => x.fieldType == "MultiSelect")
          .map((x) => `${x.fieldName}.textValue`)
          .includes(name)
      ? e.target.value.join("|")
      : e.target.value;

    _.set(newState, name, value);

    setCustomFieldState(newState);
  };

  const setFormStateFromProps = (event) => {
    var eventDate = event.eventDateTime
      ? moment(event.eventDateTime).format("YYYY-MM-DD HH:mm")
      : "";
    var selectedAssets = [];
    event.entityAssets.forEach((pa) => {
      var selectedAsset = assetLookups.filter((al) => al.id === pa.assetId)[0];
      if (selectedAsset) {
        selectedAssets.push(selectedAsset);
      }
    });

    var selectedPermits = [];
    event.entityPermits.forEach((pa) => {
      var selectedPermit = permits.find((al) => al.id === pa.permitId);
      if (selectedPermit) {
        selectedPermits.push(selectedPermit);
      }
    });

    var selectedEntityUsers = [];
    event.entityUsers.forEach((atu) => {
      var selectedAssignedToUser = users.find((au) => au.id === atu.userId);
      if (selectedAssignedToUser) {
        selectedEntityUsers.push(selectedAssignedToUser);
      }
    });
    let newState = {
      assetId: event.assetId,
      eventDateTime: eventDate,
      eventCategoryId: event.eventCategoryId,
      eventSubCategoryId: event.eventSubCategoryId,
      description: event.description,
      location: event.location,
      locationTypeId: event.locationTypeId,
      severity: event.severity,
      latitude: event.latitude,
      longitude: event.longitude,
      urgency: event.urgency,
      followUpRequired: event.followUpRequired,
      followUpDate: event.followUpDate,
      followUpDescription: event.followUpDescription,
      entityUsers: selectedEntityUsers,
      assets: selectedAssets,
      permits: selectedPermits,
      appliesToPermit: _.isEmpty(selectedPermits) ? false : true,
      eventDueDateTime: dateUtil.convertDateOnlyToLocal(event.eventDueDateTime),
      eventCustomFieldValues: [...event.eventCustomFieldValues],
    };
    if (!_.isEmpty(event.entityPermits)) {
      newState.appliesToPermit = true;
    }
    setInitialFormState(_.cloneDeep(newState));
    setDefaultLatitude(event.latitude);
    setDefaultLongitude(event.longitude);

    setFormState(newState);
  };

  const checkFollowUpForNewEvent = (form) => {
    if (
      form.eventDateTime != "" &&
      form.followUpRequired &&
      form.followUpDate != ""
    ) {
      return true;
    }
    return false;
  };

  const checkFollowUpForExistingEvent = (initialForm, newForm) => {
    if (
      initialForm.followUpRequired == "" &&
      newForm.followUpRequired != "" &&
      newForm.followUpDate != "" &&
      newForm.completedDate != ""
    ) {
      return true;
    }
    return false;
  };
  const handleSavePendingReview = () => {
    if (!validateDataForSave()) {
      return;
    }
    setReviewSaveWarning(true);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors

    let formToSave = validationService.unsetErrors(
      formState,
      "eventDateTimeError",
      "eventCategoryIdError",
      "eventSubCategoryIdError",
      "assetIdError",
      "locationError",
      "locationTypeIdError",
      "eventDueDateTimeError",
      "severityError"
    );

    formToSave = validationService.unsetFields(
      formToSave,
      "eventSubCategoryIdError",
      "locationError"
    );
    formToSave.eventDateTime =
      formToSave.eventDateTime == ""
        ? ""
        : moment(formToSave.eventDateTime).utc().format();

    let eventCustomFieldValues = transformCustomFieldState(
      eventCustomFields,
      customFieldState,
      formState.eventCategoryId,
      formState.eventSubCategoryId
    );

    formToSave = { ...formState };

    formToSave.eventContacts = eventContacts;
    formToSave.tagIds = eventTags.map((x) => x.lookupId);
    formToSave.isOffline = isOffline ? true : false;
    var entityAssets = [];
    var entityPermits = [];
    var entityUsers = [];
    formState.assets.forEach((a) =>
      entityAssets.push({ assetId: a.id, eventId: event.id ?? 0 })
    );
    formState.permits.forEach((a) =>
      entityPermits.push({ permitId: a.id, eventId: event.id ?? 0 })
    );
    formState.entityUsers.forEach((atu) =>
      entityUsers.push({ userId: atu.id, eventId: event.id ?? 0 })
    );
    formToSave.entityAssets = entityAssets;
    formToSave.entityPermits = entityPermits;
    formToSave.entityUsers = entityUsers;
    if (parentEventId > 0) {
      formToSave.parentEventId = parentEventId;
    }

    setIsUploading(true);
    // offlline mode combine events and attachments in one call
    formToSave.eventCustomFieldValues = eventCustomFieldValues;
    if (props.entityId <= 0) {
      let form = new FormData();
      var attachmentsToUpload = [];
      if (!_.isEmpty(uploadedFiles)) {
        for (var index = 0; index < uploadedFiles.length; index++) {
          let file = uploadedFiles[index];
          attachmentsToUpload.push({
            name: file.name,
            blob: file.slice(0, file.size, file.type),
          });
          var element = uploadedFiles[index];
          form.append("file", element);
        }
      }

      let dataString = JSON.stringify(formToSave);
      form.append("data", dataString);
      let guid = createGuid();
      let hasFollowUpEvent = checkFollowUpForNewEvent(formToSave);

      createEventWithAttachments(form, guid)
        .then((res) => {
          alertify.success("Event Created.");
          if (props.isForEventDetails) {
            props.handleModalClose(
              true,
              false,
              res.data,
              !_.isEmpty(props.defaultValues) ? true : false
            );
          } else {
            handleNavigateTo(
              null,
              `/events/${res.data.id}${
                hasFollowUpEvent ? "?hasFollowUp=true" : ""
              }`
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            alertify.error(err.response.data.message);
            setIsUploading(false);
          } else {
            //all offline
            localforage.getItem(eventStore).then((res) => {
              let values = [...res];
              values.push({
                id: guid,
                ...formToSave,
                notSynced: true,
                assetName: assetLookups.find((x) => x.id == formToSave.assetId)
                  .name,
                eventCategory: eventCategories.find(
                  (x) => x.id == formToSave.eventCategoryId
                ).code,
              });
              localforage.setItem(eventStore, values);
            });

            localforage
              .getItem(eventSyncStore)
              .then((res) => {
                let data = [];
                if (res !== null && res !== undefined) {
                  data = [...res];
                }

                data.push({
                  id: guid,
                  type: "event",
                  form: { ...formToSave },
                  attachments: [...attachmentsToUpload],
                });
                localforage
                  .setItem(eventSyncStore, data)
                  .then(() => {
                    alertify.success("Event saved for syncing.");
                    handleNavigateTo(null, "/events");
                  })
                  .catch((err) => {
                    alertify.error(
                      "Unable to create event. Please ensure you are not in private browsing mode."
                    );
                    handleNavigateTo(null, "/events");
                  });
              })
              .catch((err) => {
                alertify.error(
                  "Unable to create event. Please ensure you are not in private browsing mode."
                );
                handleNavigateTo(null, "/events");
              });
          }

          //add event to indexdb
        });
    }
    if (props.entityId > 0) {
      const hasFollowUpEvent = checkFollowUpForExistingEvent(
        initialFormState,
        formToSave
      );

      setIsLoading(true);
      patchEvent(props.entityId, formToSave)
        .then((result) => {
          if (!_.isEmpty(uploadedFiles)) {
            addEntityAttachments("event", props.entityId, uploadedFiles)
              .then((res) => {})
              .catch(() => {
                alertify.error("Error uploading attachments.");
                props.handleModalClose(true, false, result.data);
              });

            alertify.success(
              "Event Saved. Attachments will be available once upload is complete."
            );
            props.handleModalClose(
              true,
              false,
              result.data,
              null,
              hasFollowUpEvent
            );
          } else {
            alertify.success("Event Saved");
            props.handleModalClose(
              true,
              false,
              result.data,
              null,
              hasFollowUpEvent
            );
          }
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
          props.handleModalClose(false);
        });
    }
  };

  const deleteItem = () => {
    deleteEvent(props.entityId).then(() => {
      alertify.success("Event Deleted");
      handleNavigateTo(null, "/events");
    });
  };

  const handleDelete = () => {
    setShowDelete(true);
  };

  useEffect(() => {
    if (
      !_.isEmpty(assetLookups) &&
      (formState.assetId || !_.isEmpty(formState.assets))
    ) {
      var assetId = organization.multipleAssetEvents
        ? _.last(formState.assets).id
        : formState.assetId;
      let asset = assetLookups.find((x) => x.id == assetId);
      if (asset) {
        if (!locationTouched) {
          setDefaultLatitude(asset.centroidLatitude);
          setDefaultLongitude(asset.centroidLongitude);
        }

        getAssetLayerGeojson(asset.id);
      }
    }
  }, [formState.assetId, assetLookups, formState.assets]);

  const validateDataForSave = () => {
    let newState = { ...formState };
    let newCustomFieldState = { ...customFieldState };
    let isFormValid = false;
    if (formState)
      if (isScheduledEvent()) {
        validationService.validateRequiredField(
          newState,
          "eventDueDateTime",
          "eventDueDateTimeError",
          "Due Date"
        );
      }
    if (props.entityId <= 0 && !isScheduledEvent()) {
      validationService.validateRequiredField(
        newState,
        "eventDateTime",
        "eventDateTimeError",
        "Completed Date"
      );
    }
    if (formState.appliesToPermit) {
      validationService.validateRequiredField(
        newState,
        "permits",
        "permitsError",
        "Associated Permits"
      );
    }
    validationService.validateRequiredField(
      newState,
      "eventCategoryId",
      "eventCategoryIdError",
      "Event Category"
    );
    if (isSubCatRequired()) {
      validationService.validateRequiredField(
        newState,
        "eventSubCategoryId",
        "eventSubCategoryIdError",
        "Subcategory"
      );
    }
    if (organization.multipleAssetEvents) {
      validationService.validateRequiredField(
        newState,
        "assets",
        "assetsError",
        "Associated Assets"
      );
    } else {
      validationService.validateRequiredField(
        newState,
        "assetId",
        "assetIdError",
        "Asset"
      );
    }

    if (!isScheduledEvent() && formState.eventDateTime != "") {
      eventCustomFields
        .filter(
          (x) =>
            x.isRequired &&
            !x.isDeleted &&
            (_.toLower(x.fieldType) == "dropdown" ||
              _.toLower(x.fieldType) == "text" ||
              _.toLower(x.fieldType) == "textarea" ||
              _.toLower(x.fieldType) == "multiselect")
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.textValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      eventCustomFields
        .filter(
          (x) =>
            x.isRequired && !x.isDeleted && _.toLower(x.fieldType) == "datetime"
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateTimeValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      eventCustomFields
        .filter(
          (x) =>
            x.isRequired && !x.isDeleted && _.toLower(x.fieldType) == "date"
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
    }

    eventCustomFields
      .filter((x) => _.toLower(x.fieldType) == "number")
      .forEach((value) => {
        validationService.validateNumberField(
          newCustomFieldState,
          `${value.fieldName}.numericValue`,
          `${value.fieldName}Error`,
          !isScheduledEvent() &&
            formState.eventDateTime != "" &&
            value.isRequired,
          `${value.fieldLabel}`
        );
      });

    var customFieldErrorsToUnset = selectedCustomFields
      .filter((x) =>
        !isScheduledEvent() && formState.eventDateTime != ""
          ? x.isRequired || _.toLower(x.fieldType) == "number"
          : _.toLower(x.fieldType) == "number"
      )
      .map((field) => `${field.fieldName}Error`);
    if (
      newState.latitude === null ||
      newState.latitude === undefined ||
      newState.longitude === null ||
      newState.longitude === undefined
    ) {
      newState.locationError = "A valid location is required.";
    } else {
      newState.locationError = "";
    }
    isFormValid =
      !validationService.hasError(
        newState,
        isScheduledEvent()
          ? "eventDueDateTimeError"
          : props.entityId <= 0
          ? "eventDateTimeError"
          : "",
        organization.multipleAssetEvents ? "assetsError" : "assetIdError",
        formState.appliesToPermit ? "permitsError" : "",
        "eventCategoryIdError",
        "eventSubCategoryIdError",
        "assetIdError",
        "locationTypeIdError",
        "locationError"
      ) &&
      !validationService.hasError(
        newCustomFieldState,
        ...customFieldErrorsToUnset
      );

    if (props.entityId > 0) {
      //due date or completed date required for existing event
      let dueDateValid = true;
      if (newState.eventDateTime == "") {
        dueDateValid = validationService.validateRequiredField(
          newState,
          "eventDueDateTime",
          "eventDueDateTimeError",
          "Due Date or Completed Date"
        );
      }

      let completedDateValid = validationService.validateRequiredField(
        newState,
        "eventDateTime",
        "eventDateTimeError",
        "Completed Date or Due Date"
      );

      if (!dueDateValid && !completedDateValid) {
        isFormValid = false;
      }
    }

    if (!isFormValid) {
      setFormState(newState);
      setCustomFieldState(newCustomFieldState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleLatLongChange = (lat, long) => {
    setFormState((existingState) => {
      return { ...existingState, latitude: lat, longitude: long };
    });
  };

  const isSubCatRequired = () => {
    if (!_.isEmpty(eventCategories) && formState.eventCategoryId) {
      var cat = eventCategories.find((x) => x.id == formState.eventCategoryId);

      if (cat) {
        return cat.requireChildSelection;
      }
    }
    return false;
  };

  const isAssignedToDisabled = () => {
    return (
      eventLocked ||
      (!organization.multipleAssetEvents &&
        (formState.eventCategoryId == "" || formState.assetId == "")) ||
      (organization.multipleAssetEvents && formState.eventCategoryId == "")
    );
  };

  const getFormRow = () => {
    return (
      <Grid.Row>
        <Container className="form-container" maxWidth="xl">
          <Dimmer active={isLoading} loader>
            <Form className="card">
              <Card.Body className="pt-0 pb-0 pl-0 pr-0">
                <Grid.Row>
                  {!isLoading && (
                    <>
                      {event.status == EventStatuses.PendingEmailReview && (
                        <Accordion
                          expanded={corLogExpanded}
                          onChange={() => setCorLogExpanded(!corLogExpanded)}
                          className="w-100"
                        >
                          <AccordionSummary
                            aria-controls="cor-log-content"
                            id="cor-log-header"
                          >
                            <Typography>
                              Email Review + Correspondence Log
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid.Row>
                              <Grid.Col md={12} width={12}>
                                <Typography>
                                  This event was created by a forwarded email.
                                  Review the email and correspodence log below
                                  and fill out the event form to create the
                                  correspondence as a new event. If this
                                  correspondence belongs to an existing
                                  event,&nbsp;
                                  <Link
                                    underline="hover"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowMergeEvent(true);
                                    }}
                                  >
                                    click here
                                  </Link>
                                  &nbsp;to merge the correspondence to the
                                  correct event.
                                </Typography>
                              </Grid.Col>
                              <Grid.Col md={12} width={12} className="mt-2">
                                <EventDetailsCorrespondenceLog
                                  isEventForm
                                  noHeader
                                  event={event}
                                  handleAddCorrespondenceLog={null}
                                />
                              </Grid.Col>
                            </Grid.Row>
                          </AccordionDetails>
                        </Accordion>
                      )}

                      <Accordion
                        expanded={detailsExpanded}
                        onChange={() => setDetailsExpanded(!detailsExpanded)}
                        className="w-100"
                      >
                        <AccordionSummary
                          aria-controls="details-content"
                          id="details-header"
                        >
                          <Typography>Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid.Row>
                            <Grid.Col md={12} width={12}>
                              {!_.isEmpty(assetLookups) &&
                                organization.multipleAssetEvents && (
                                  <Form.Group
                                    isRequired
                                    label="Associated Assets"
                                  >
                                    <MultiSelect
                                      name="assets"
                                      onChange={handleInputChange}
                                      value={formState.assets}
                                      dropdownValues={assetLookups}
                                      key="assetId"
                                      label="name"
                                      id="assetId"
                                      error={formState.assetsError}
                                    />
                                  </Form.Group>
                                )}
                              {!organization.multipleAssetEvents && (
                                <Form.Group isRequired label="Asset">
                                  <Form.Select
                                    name="assetId"
                                    onChange={handleInputChange}
                                    value={formState.assetId}
                                    error={formState.assetIdError}
                                    disabled={
                                      props.assetDisabled || eventLocked
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value={""}></option>
                                    {assetLookups.map((asset) => (
                                      <option value={asset.id} key={asset.id}>
                                        {asset.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              )}
                            </Grid.Col>
                            {organization.hasPermitFeature && (
                              <Grid.Col md={12} width={12}>
                                <Form.Group className="" label="">
                                  <Form.Checkbox
                                    label="Applies to Permit?"
                                    name="appliesToPermit"
                                    checked={formState.appliesToPermit}
                                    onChange={handleInputChange}
                                    disabled={eventLocked}
                                  />
                                </Form.Group>
                              </Grid.Col>
                            )}
                            <Grid.Col md={12} width={12}>
                              {formState.appliesToPermit &&
                                organization.hasPermitFeature && (
                                  <Form.Group
                                    isRequired
                                    label="Associated Permits"
                                  >
                                    <MultiSelect
                                      name="permits"
                                      onChange={handleInputChange}
                                      value={formState.permits}
                                      dropdownValues={permits}
                                      key="id"
                                      label={
                                        organization.eventPermitLabelFunction
                                          ? null
                                          : "permitNumber"
                                      }
                                      labelFunction={
                                        organization.eventPermitLabelFunction
                                          ? (value) => {
                                              return eval(
                                                organization.eventPermitLabelFunction
                                              );
                                            }
                                          : null
                                      }
                                      id="permitId"
                                      error={formState.permitsError}
                                    />
                                  </Form.Group>
                                )}
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group isRequired label="Category">
                                <Form.Select
                                  name="eventCategoryId"
                                  value={formState.eventCategoryId}
                                  onChange={handleInputChange}
                                  error={formState.eventCategoryIdError}
                                  disabled={eventLocked}
                                >
                                  <option value={""}></option>
                                  {eventCategories.map((type) => (
                                    <option value={type.id} key={type.id}>
                                      {type.code}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group
                                label="Subcategory"
                                isRequired={isSubCatRequired()}
                              >
                                <Form.Select
                                  name="eventSubCategoryId"
                                  onChange={handleInputChange}
                                  value={formState.eventSubCategoryId}
                                  error={formState.eventSubCategoryIdError}
                                  disabled={
                                    eventLocked ||
                                    formState.eventCategoryId == ""
                                  }
                                >
                                  <option value={""}></option>
                                  {selectedEventSubCategories.map((type) => (
                                    <option value={type.id} key={type.id}>
                                      {type.code}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Grid.Col>

                            <Grid.Col md={6} width={12}>
                              <Form.Group
                                label="Due Date"
                                isRequired={isScheduledEvent()}
                              >
                                <Form.Input
                                  type="date"
                                  value={formState.eventDueDateTime}
                                  name="eventDueDateTime"
                                  onChange={handleInputChange}
                                  disabled={eventLocked}
                                  error={formState.eventDueDateTimeError}
                                />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group
                                isRequired={
                                  !isScheduledEvent() && props.entityId <= 0
                                }
                                label="Event Date / Completed Date"
                              >
                                <Form.Input
                                  type="datetime-local"
                                  value={formState.eventDateTime}
                                  name="eventDateTime"
                                  onChange={handleInputChange}
                                  error={formState.eventDateTimeError}
                                  disabled={eventLocked}
                                />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group label="Assigned To">
                                <MultiSelect
                                  name="entityUsers"
                                  id="entityUsers"
                                  disabled={isAssignedToDisabled()}
                                  onChange={handleInputChange}
                                  value={formState.entityUsers}
                                  dropdownValues={availableUsers}
                                  key="id"
                                  label="fullName"
                                />
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group label="Urgency">
                                <Form.Select
                                  name="urgency"
                                  onChange={handleInputChange}
                                  value={formState.urgency}
                                  error={formState.urgencyError}
                                  disabled={eventLocked}
                                >
                                  <option value={""}></option>
                                  {urgencyList.map((type) => (
                                    <option value={type.value} key={type.value}>
                                      {type.value}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Grid.Col>

                            <Grid.Col md={12} width={12}>
                              <Form.Group label="Description">
                                <LynxTextArea
                                  name="description"
                                  onChange={handleInputChange}
                                  value={formState.description}
                                  disabled={eventLocked}
                                ></LynxTextArea>
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={12} width={12}>
                              <Form.Group label="">
                                <Form.Checkbox
                                  label="Confidential?"
                                  name="isConfidential"
                                  checked={formState.isConfidential}
                                  onChange={handleInputChange}
                                />
                              </Form.Group>
                            </Grid.Col>
                            {_.isEmpty(props.defaultValues) && (
                              <>
                                {" "}
                                <Grid.Col md={6} width={12}>
                                  <Form.Group label="">
                                    <Form.Checkbox
                                      label="Follow up required?"
                                      name="followUpRequired"
                                      checked={formState.followUpRequired}
                                      onChange={handleInputChange}
                                      disabled={eventLocked}
                                    />
                                  </Form.Group>
                                </Grid.Col>
                                {formState.followUpRequired && (
                                  <>
                                    <Grid.Col md={6} width={12}>
                                      <Form.Group label="Follow Up Date">
                                        <Form.Input
                                          type="date"
                                          value={
                                            formState.followUpDate
                                              ? moment
                                                  .utc(formState.followUpDate)
                                                  .format("YYYY-MM-DD")
                                              : ""
                                          }
                                          name="followUpDate"
                                          onChange={handleInputChange}
                                          disabled={eventLocked}
                                        />
                                      </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col md={12} width={12}>
                                      <Form.Group label="Follow Up Description">
                                        <LynxTextArea
                                          name="followUpDescription"
                                          onChange={handleInputChange}
                                          value={formState.followUpDescription}
                                          disabled={eventLocked}
                                        ></LynxTextArea>
                                      </Form.Group>
                                    </Grid.Col>
                                  </>
                                )}
                              </>
                            )}
                          </Grid.Row>
                        </AccordionDetails>
                      </Accordion>
                      {!_.isEmpty(selectedCustomFields) && (
                        <>
                          <Accordion
                            expanded={customFieldsExpanded}
                            onChange={() =>
                              setCustomFieldsExpanded(!customFieldsExpanded)
                            }
                            className="w-100"
                          >
                            <AccordionSummary
                              aria-controls="custom-fields-content"
                              id="custom-fields-header"
                            >
                              <Typography>Additional Fields</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid.Row>
                                {selectedCustomFields
                                  .sort((a, b) =>
                                    a.position > b.position ? 1 : -1
                                  )
                                  .filter((x) => !x.isDeleted)
                                  .map((field) => (
                                    <CustomField
                                      customField={field}
                                      key={field.position}
                                      isRequired={
                                        field.isRequired &&
                                        !isScheduledEvent() &&
                                        formState.eventDateTime != ""
                                      }
                                      handleInputChange={
                                        handleCustomFieldChange
                                      }
                                      formState={customFieldState}
                                      disabled={eventLocked}
                                    ></CustomField>
                                  ))}
                              </Grid.Row>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      )}
                    </>
                  )}
                  {props.entityId <= 0 && !isOffline && (
                    <>
                      <Accordion className="w-100">
                        <AccordionSummary
                          aria-controls="tags-content"
                          F
                          id="tags-header"
                        >
                          <Typography>Tags</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Tags
                            entityType={EntityTypes.Event}
                            entityId={null}
                            handleSetTags={(e) => {
                              setEventTags(e);
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}

                  {props.entityId <= 0 && !isOffline && (
                    <>
                      <Accordion className="w-100">
                        <AccordionSummary
                          aria-controls="contacts-content"
                          id="contacts-header"
                        >
                          <Typography>Contacts</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Contacts
                            entityType={EntityTypes.Event}
                            entityId={null}
                            handleSetContacts={(e) => {
                              setEventContacts(e);
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                  <>
                    {props.entityId <= 0 && (
                      <Accordion className="w-100">
                        <AccordionSummary
                          aria-controls="attachments-content"
                          id="attachments-header"
                        >
                          <Typography>Attachments</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <AttachmentViewer
                            isExistingEntity={props.entityId > 0}
                            entityType={"Event"}
                            entityId={event.id}
                            cardClass="mb-0"
                            handleSetUploadedFiles={(files) => {
                              setUploadedFiles(files);
                            }}
                            rowHeight={200}
                            cols={6}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </>
                  {props.entityId > 0 && !_.isEmpty(linkedEvents) && (
                    <>
                      <Accordion className="w-100">
                        <AccordionSummary
                          aria-controls="linked-events-content"
                          id="linked-events-header"
                        >
                          <Typography>Linked Events</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid.Row>
                            <Grid.Col md={12} width={12}>
                              <LinkedEvents events={linkedEvents} />
                            </Grid.Col>
                          </Grid.Row>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                  <Accordion
                    className="w-100"
                    expanded={locationExpanded}
                    onChange={() => setLocationExpanded(!locationExpanded)}
                  >
                    <AccordionSummary
                      aria-controls="location-content"
                      id="location-header"
                    >
                      <Typography>Location</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid.Row>
                        <LocationSelectionControl
                          defaultLatitude={defaultLatitude}
                          defaultLongitude={defaultLongitude}
                          isRequired
                          handleLatLongChange={handleLatLongChange}
                          handleLocationTouched={() => setLocationTouched(true)}
                          supportingGeojson={selectedAssetGeojson}
                          error={formState.locationError}
                          entityType={EntityTypes.Event}
                        />
                      </Grid.Row>
                    </AccordionDetails>
                  </Accordion>
                </Grid.Row>
              </Card.Body>
              <Card.Footer>
                {(!props.entityId || (props.entityId <= 0 && !isLoading)) && (
                  <Button
                    variant="contained"
                    className="float-right"
                    onClick={saveForm}
                  >
                    {isScheduledEvent() ? "Schedule Event" : "Add Event"}
                  </Button>
                )}
                {props.entityId > 0 && !isLoading && (
                  <div>
                    {(roleMatch([UserRoles.Admin]) ||
                      event.status == EventStatuses.PendingEmailReview) && (
                      <Button
                        variant="contained"
                        color="error"
                        className="float-left"
                        onClick={handleDelete}
                      >
                        Delete Event
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      className="float-right"
                      onClick={
                        event.status == EventStatuses.PendingEmailReview
                          ? handleSavePendingReview
                          : saveForm
                      }
                    >
                      Save Event
                    </Button>
                  </div>
                )}
              </Card.Footer>
              {showDelete && (
                <LynxDialog
                  open={showDelete}
                  handleClose={() => setShowDelete(false)}
                  handleDelete={deleteItem}
                  title={`Delete Event?`}
                  description={"All attachments will also be deleted."}
                />
              )}
              {showFileSizeError && (
                <LynxDialog
                  open={showFileSizeError}
                  handleClose={() => setShowFileSizeError(false)}
                  title={`File size exceeded`}
                  description={
                    "Attachments are over the required total size limit of 50MB."
                  }
                  handleConfirm={() => setShowFileSizeError(false)}
                />
              )}
              {isUploading && (
                <LynxDialog
                  open={isUploading}
                  title={`Saving Event. Do not close the window.`}
                  dialogContent={
                    <>
                      <div className="d-flex align-items-center justify-content-center mt-4">
                        <CircularProgress />
                      </div>
                    </>
                  }
                />
              )}
              {showMergeEvent && (
                <LynxDialog
                  open={showMergeEvent}
                  maxWidth="lg"
                  fullWidth
                  title={`Merge Correspondence`}
                  onClose={() => setShowMergeEvent(false)}
                  dialogContent={<EventCorrespondenceMerge event={event} />}
                />
              )}

              {reviewSaveWarning && (
                <LynxDialog
                  open={reviewSaveWarning}
                  title={`Save Event?`}
                  handleSave={() => {
                    saveForm();
                  }}
                  handleClose={() => {
                    setReviewSaveWarning(false);
                  }}
                  dialogContent={
                    <>
                      <div>
                        Asset(s):{" "}
                        {formState.assetId && formState.assetId > 0
                          ? assetLookups.find((x) => x.id == formState.assetId)
                              .name
                          : formState.assets.map((a) => a.name).join(", ")}
                      </div>
                      <div>
                        Category:{" "}
                        {formState.eventCategoryId &&
                          formState.eventCategoryId > 0 &&
                          eventCategories.find(
                            (x) => x.id == formState.eventCategoryId
                          ).code}
                      </div>
                    </>
                  }
                />
              )}
              {showAddContact && (
                <LynxDialog
                  dividers
                  hasNoActions
                  isCloseInHeader
                  open={showAddContact}
                  handleClose={() => setShowAddContact(false)}
                  title={`Add Contact`}
                  maxWidth="md"
                  dialogContent={
                    <ContactForm
                      modalMode
                      handleSave={(contact) => {
                        setShowAddContact(false);
                        setAllContacts([...allContacts, contact]);
                      }}
                    />
                  }
                />
              )}
            </Form>
          </Dimmer>
        </Container>
      </Grid.Row>
    );
  };
  if (props.isForEventDetails) {
    return getFormRow();
  } else
    return (
      <Grid>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  component="div"
                  className="form-header-text"
                >
                  {isScheduledEvent() ? "Schedule Event" : "Add Event"}
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/events")}
                >
                  Events
                </Link>
                <Typography color="text.primary">
                  {isScheduledEvent() ? "Schedule Event" : "Add Event"}
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        {getFormRow()}
      </Grid>
    );
}
