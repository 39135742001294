import LockIcon from "@mui/icons-material/Lock";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getStatusChip } from "../actions/action-form";
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
}));

export function EventDetailsActions(props) {
  const [actions, setActions] = useState([]);

  const history = useHistory();
  useEffect(() => {
    setActions(props.event.actions);
  }, [props.event.actions]);

  const handleAddAction = () => {
    history.push(`/events/${props.event.id}/add-action`);
  };

  const handleActionNumberClick = (e, action) => {
    e.preventDefault();
    history.push(`/events/${props.event.id}/actions/${action.id}`);
  };
  return (
    <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
      <CardHeader
        className="lynx-card-header"
        title="Actions"
        action={
          props.eventLocked ? (
            <LockIcon className="mt-3 mr-3" color="disabled" fontSize="small" />
          ) : (
            <Button onClick={handleAddAction}>Add Linked Action</Button>
          )
        }
      />

      <CardContent>
        {_.isEmpty(actions) && <div>No actions</div>}
        <div className="compliance-grid-container">
          {actions.map((action) => (
            <div className="dashboard-item">
              {getActionCard(handleActionNumberClick, action)}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export function getActionCard(handleActionNumberClick, action) {
  return (
    <Item variant="outlined">
      <div className="d-flex">
        <Link
          href="#"
          underline="hover"
          onClick={(e) => handleActionNumberClick(e, action)}
        >
          <Typography variant="h6" component="div">
            {action.actionNumber}
          </Typography>
        </Link>
        <div className="ml-2"> {getStatusChip(action, "small")}</div>
      </div>

      <Typography sx={{ mb: 1.5 }} color="text.secondary" className="mb-0">
        {action.category}
      </Typography>
      <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
        {action.title}
      </Typography>
    </Item>
  );
}
