import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { roleMatch } from "../../../actions/auth";
import { updateIncidentInspection } from "../../../services/incident-service";
import { validationService } from "./../../../services/validation";
import "./incident-form.css";

import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { canSubmitInvestigation } from "services/incident-assignment-service";
import { dateUtil } from "../../../services/date-util";
import {
  convertInspectionStateToValueObject,
  getErrorFieldArray,
  getFormFields,
  getFullStateFromInspectionDef,
  getInspectionForm,
  getStateFromExistingInspection,
} from "../../../services/inspection-form-service";
import { CustomFieldTypes, UserRoles } from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import { InspectionFormField } from "../inspections/inspection-form-field";
import { IncidentInvestigationTeam } from "./incident-investigation-team";
export function IncidentInvestigation(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [inspectionState, setInspectionState] = useState({});
  const [inspectionForm, setInspectionForm] = useState({
    inspectionFormFields: [],
  });
  const [isSaving, setIsSaving] = useState(false);
  var account = useSelector((state) => state.account);
  const isInitiallyLocked = props.incident.status.toLowerCase() == "closed";
  const [isLocked, setIsLocked] = useState({
    investigationTeam: isInitiallyLocked,
    investigationForm: isInitiallyLocked,
  });

  useEffect(() => {
    if (!_.isEmpty(props.incident)) {
      if (props.incident.investigationInspectionFormId) {
        loadInvestigationForm(props.incident.investigationInspectionFormId);
      }
    }
  }, [props.incident]);

  let incidentStatus = _.toLower(props.incident.status);

  const loadInvestigationForm = (id) => {
    getInspectionForm(id).then((res) => {
      setInspectionForm(res.data);
      //prepare inspection form
      let fullState = getFullStateFromInspectionDef(
        res.data.inspectionFormFields
      );
      //merge existing values
      let existingInspectionState = getStateFromExistingInspection(
        props.incident.investigationInspectionFormFieldValues
      );

      fullState = { ...fullState, ...existingInspectionState };
      setInspectionState(fullState);
    });
  };

  const handleInspectionInputChange = (e, sigName, sigInput) => {
    let newState = { ...inspectionState };
    let name = sigName ?? e.target.name;
    const editedField = inspectionForm.inspectionFormFields.find(
      (x) => x.name == name
    );
    let value = sigName
      ? sigInput
      : e.target.type == "checkbox"
      ? e.target.checked
      : editedField && editedField.type == CustomFieldTypes.MultiSelect
      ? e.target.value.join("|")
      : e.target.value;
    _.set(newState, name, value);

    setInspectionState(newState);
  };
  const validateSubmitDataForSave = () => {
    let newState = { ...inspectionState };
    let isFormValid = false;
    getFormFields(inspectionForm.inspectionFormFields)
      .filter((x) => x.isRequired)
      .forEach((field) => {
        validationService.validateRequiredField(
          newState,
          field.name,
          `${field.name}Error`,
          field.label
        );
      });

    isFormValid = !validationService.hasError(
      newState,
      ...getErrorFieldArray(inspectionForm.inspectionFormFields)
    );

    if (!isFormValid) {
      setInspectionState(newState);
    }
    if (!isFormValid) {
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };
  const saveIncident = (saveAction) => {
    if (saveAction == "investigationsubmit") {
      if (!validateSubmitDataForSave()) {
        return;
      }
    }
    setIsSaving(true);
    const errorFields = getErrorFieldArray(inspectionForm.inspectionFormFields);
    let inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    var objectListToSave = convertInspectionStateToValueObject(
      inspectionForm.inspectionFormFields,
      inspectionFormValuesToSave
    );

    updateIncidentInspection(props.incident.id, objectListToSave, saveAction)
      .then((res) => {
        props.updateIncident(res.data);
        alertify.success(
          saveAction == "investigationsubmit"
            ? "Investigation submitted."
            : "Investigation saved."
        );
        setIsSaving(false);
        if (isInitiallyLocked) {
          resetIsLocked();
        }
      })
      .catch((err) => {
        alertify.error(err.response.data.message);
        setIsSaving(false);
      });
  };

  const canSubmit = () => {
    return canSubmitInvestigation(props.incident, account.id);
  };

  const getButtons = () => {
    return (
      <>
        {canSubmit() && (
          <Button
            className="float-right ml-2"
            variant="contained"
            onClick={() => saveIncident("investigation")}
            disabled={incidentStatus == "closed" && isLocked.investigationForm}
          >
            Save
          </Button>
        )}
        {incidentStatus == "investigation" && canSubmit() && (
          <Button
            className="float-right"
            variant="contained"
            color="success"
            disabled={incidentStatus == "closed"}
            onClick={() => saveIncident("investigationsubmit")}
          >
            Save and Submit
          </Button>
        )}
      </>
    );
  };

  const toggleLock = (name) => {
    const newState = { ...isLocked };
    const current = _.get(newState, name);
    _.set(newState, name, !current);
    setIsLocked(newState);
  };

  const resetIsLocked = () => {
    setIsLocked({
      investigationForm: isInitiallyLocked,
      investigationTeam: isInitiallyLocked,
    });
  };

  return (
    <Container className="mt-2" maxWidth="xl">
      <Dimmer active={isLoading} loader>
        <IncidentInvestigationTeam
          incident={props.incident}
          onUpdateInvestigationTeam={(data) => props.updateIncident(data)}
          isLocked={isLocked.investigationTeam}
          toggleLock={toggleLock}
          resetIsLocked={resetIsLocked}
        />
        <Form className="card mb-5">
          <Card.Header>
            <Card.Title>
              <Stack direction="row" alignItems="center">
                <span>Incident Investigation Form</span>
                {roleMatch([UserRoles.Admin]) && isInitiallyLocked && (
                  <IconButton
                    aria-label="Lock"
                    onClick={() => toggleLock("investigationForm")}
                    title={isLocked.investigationForm ? "Unlock" : "Lock"}
                  >
                    {isLocked.investigationForm ? (
                      <Lock />
                    ) : (
                      <LockOpenOutlined color="success" />
                    )}
                  </IconButton>
                )}
              </Stack>
            </Card.Title>
            <div className="ml-auto">{getButtons()}</div>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              {!_.isEmpty(inspectionState) &&
                _.orderBy(inspectionForm.inspectionFormFields, [
                  "position",
                ]).map((field) => {
                  return (
                    <InspectionFormField
                      field={field}
                      value={inspectionState[field.name]}
                      signatureValue={
                        field.type == CustomFieldTypes.Signature
                          ? inspectionState[field.name + "_signature"]
                          : null
                      }
                      handleInputChange={handleInspectionInputChange}
                      error={inspectionState[`${field.name}Error`]}
                      key={field.name}
                      disabled={isLocked.investigationForm || !canSubmit}
                    />
                  );
                })}
            </Grid.Row>
          </Card.Body>

          <Card.Footer>
            {getButtons()}
            {props.incident.investigationSubmittedDateTimeUtc && (
              <div>
                <Typography component={"span"}>
                  Investigation submitted by:{" "}
                  {props.incident.investigationSubmittedByUserFullName}{" "}
                  {dateUtil.convertDateTimeToLocal(
                    props.incident.investigationSubmittedDateTimeUtc
                  )}
                </Typography>
              </div>
            )}
          </Card.Footer>
        </Form>
      </Dimmer>
      {isSaving && (
        <LynxDialog
          open={isSaving}
          title="Saving investigation. Do not close the window."
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}
    </Container>
  );
}
