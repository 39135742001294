import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import _ from "lodash";
import { BootstrapInput } from "../bootstrap-inputs";
import Divider from "@mui/material/Divider";

export function MultiSelect(props) {
  const [open, setOpen] = useState(false);
  return (
    <FormControl sx={{ width: "100%" }} disabled={props.disabled}>
      <Select
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
        }}
        displayEmpty
        name={props.name}
        labelId="multiSelectLabel"
        id={props.id}
        multiple
        open={open}
        onOpen={() => {
          if (props.onOpen) {
            props.onOpen();
          }
          setOpen(true);
        }}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
          setOpen(false);
        }}
        value={props.value}
        onChange={(e) => props.onChange(e)}
        input={
          <BootstrapInput
            id="select-multiple-chip"
            label="Chip"
            error={props.error}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {_.sortBy(selected, [
              (value) =>
                props.labelFunction
                  ? props.labelFunction(value)
                  : props.label
                  ? value[props.label].toLowerCase()
                  : value,
            ]).map((value, i) => (
              <Chip
                variant="outlined"
                className="multi-select-chip"
                key={i}
                label={
                  props.labelFunction
                    ? props.labelFunction(value)
                    : props.label
                    ? value[props.label]
                    : value
                }
              />
            ))}
          </Box>
        )}
      >
        {Boolean(props.header) && props.header}
        {Boolean(props.header) && <Divider />}

        {_.sortBy(props.dropdownValues, [
          (value) =>
            props.labelFunction
              ? props.labelFunction(value)
              : props.label
              ? value[props.label].toLowerCase()
              : value,
        ]).map((value, i) => {
          const isChecked = props.keyProperty
            ? props.value
                .map((m) => m[props.keyProperty])
                .some((s) => s == value[props.keyProperty])
            : props.isString
            ? props.value.includes(value)
            : _.some(props.value, value);
          return (
            <MenuItem key={i} value={value} className="multi-select-option">
              <Checkbox checked={isChecked} />
              <ListItemText
                primary={
                  props.labelFunction
                    ? props.labelFunction(value)
                    : props.label
                    ? value[props.label]
                    : value
                }
              />
              {props.hasRightCheckbox && props.onChangeRightCheckbox && (
                <Checkbox
                  disabled={!isChecked}
                  checked={
                    props.value.find(
                      (f) => f[props.keyProperty] == value[props.keyProperty]
                    )?.isDepartmentHead || false
                  }
                  onChange={(e) => {
                    e.stopPropagation();
                    props.onChangeRightCheckbox(e, value);
                  }}
                  sx={{ mx: 5 }}
                />
              )}
            </MenuItem>
          );
        })}
      </Select>

      {props.error && (
        <span className="invalid-feedback" style={{ display: "inline" }}>
          {props.error}
        </span>
      )}
    </FormControl>
  );
}
