const { apiService } = require("../apiService");

const BASE_ROUTE = "/roles";

const rolesEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => BASE_ROUTE,
    }),
  }),
});

export const { useGetRolesQuery } = rolesEndpoint;
