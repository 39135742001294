import React, { useState, useEffect } from "react";
import { Icon, Grid, Card, Form, Dimmer } from "tabler-react";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import Button from "@mui/material/Button";
import { validationService } from "./../../../services/validation";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
const initialForm = {
  name: "",
  nameError: "",
  details: "",

  participantTypeId: "",
  participantTypeIdError: "",
};

export function ParticipantModal(props) {
  const [formState, setFormState] = useState(initialForm);
  useEffect(() => {
    if (!_.isEmpty(props.participant)) {
      setFormStateFromProps(props.participant);
    }
  }, [props.participant]);
  const isExistingParticipant = () => {
    return props.participant && !_.isEmpty(props.participant);
  };

  const setFormStateFromProps = (participant) => {
    setFormState({
      name: participant.name,
      details: participant.details,
      participantTypeId: participant.participantTypeId,
      id: participant.id,
      newId: participant.newId,
    });
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const getEntityName = () => {
    if (props.isWitness) return "Witness";
    return "Affected Party";
  };

  const addParticipant = () => {
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(
      formState,
      "nameError",

      "participantTypeIdError"
    );
    if (isExistingParticipant()) {
      props.handleUpdate(formToSave);
    } else {
      props.handleAdd(formToSave);
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    validationService.validateRequiredField(
      newState,
      "participantTypeId",
      "participantTypeIdError",
      "Type"
    );

    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "participantTypeIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
    }
    return isFormValid;
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Form className="card dsmodal-main lynx-modal">
        <Card.Header>
          <Card.Title>
            {isExistingParticipant()
              ? `Edit ${getEntityName()}`
              : `Add ${getEntityName()}`}
          </Card.Title>
          <Icon
            name="x"
            onClick={props.handleClose}
            className="ml-auto pointer"
          ></Icon>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Name" isRequired>
                <Form.Input
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                  value={formState.name}
                  error={formState.nameError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Type" isRequired>
                <Form.Select
                  name="participantTypeId"
                  onChange={handleInputChange}
                  value={formState.participantTypeId}
                  error={formState.participantTypeIdError}
                >
                  <option value={""}></option>
                  {props.participantTypes.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.code}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Contact Details">
                <LynxTextArea
                  name="details"
                  onChange={handleInputChange}
                  value={formState.details}
                ></LynxTextArea>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        <Card.Footer>
          {isExistingParticipant() ? (
            <Button
              variant="contained"
              className="float-right"
              onClick={addParticipant}
            >
              Save {getEntityName()}
            </Button>
          ) : (
            <Button
              variant="contained"
              className="float-right"
              onClick={addParticipant}
            >
              Add {getEntityName()}
            </Button>
          )}
        </Card.Footer>
      </Form>
    </Dialog>
  );
}
