import Button from "@mui/material/Button";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  bulkUpdateEventAttachments,
  bulkUpdateEvents,
  getEvents,
  getEventsAttachments,
  getEventsExportAsXLSX,
} from "../../../services/events";
import { getEventLookups } from "../../../services/lookup";
import { getCustomFields } from "../../../services/custom-fields-service";
import { eventGridTourSteps } from "../../../tours/event-grid-tour";
import { EntityTypes, EventStatuses, TourTypes } from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { LynxDialog } from "../../lynx-dialog";
import useOnlineStatus from "./../../../hooks/useOnlineStatus";
import { transformCustomFieldDefsToColumnDefs } from "./../../../services/custom-fields-service";
import {
  eventStore,
  offlineDataService,
} from "./../../../services/offlineDataService";
import { LynxTour } from "./../../lynx-tour";
import { getEventColumns, latLongColumns, utmColumns } from "./event-columns";
import { EventMobileCard } from "./event-mobile-card";
export function EventList(props) {
  const [eventCustomFields, setEventCustomFields] = useState(null);
  const [columns, setColumns] = useState([]);
  const [complianceValues, setComplianceValues] = useState([]);
  const [isOfflineReady, setIsOfflineReady] = useState(true);

  const isOffline = !useOnlineStatus();
  var account = useSelector((state) => state.account);
  var organization = useSelector((state) => state.organization);
  const useUtm = account.coordinateType == "LatLong" ? false : true;

  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };

  useEffect(() => {
    getCustomFields(EntityTypes.Event)
      .then((res) => {
        let eventCustomFields = res.data;
        setEventCustomFields(eventCustomFields);
      })
      .catch((err) => {
        setEventCustomFields([]);
      });
  }, []);

  useEffect(() => {
    if (isOffline) {
      let isReady = true;

      offlineDataService.checkIfOfflineIsReady().then((res) => {
        if (!res) {
          isReady = false;
        }
        setIsOfflineReady(isReady);
      });
    }
  }, [isOffline]);

  useEffect(() => {
    if (
      eventCustomFields != null &&
      !account.isLoading &&
      !organization.isLoading
    ) {
      let newColumns = getEventColumns(
        organization.hasPermitFeature,
        organization.multipleAssetEvents,
        predefinedLookups
      );

      newColumns = [
        ...newColumns,
        ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
      ];
      if (useUtm) {
        newColumns = [...newColumns, ...utmColumns];
      } else {
        newColumns = [...newColumns, ...latLongColumns];
      }
      setDefaultColumns(newColumns);
    }
  }, [eventCustomFields, account.isLoading, organization.isLoading]);
  const handleEventNumberClick = (row) => {
    if (row.status == EventStatuses.PendingEmailReview) {
      props.history.push(`/events/${row.id}?edit=true`);
    } else {
      props.history.push(`/events/${row.id}`);
    }
  };
  const setDefaultColumns = (columns) => {
    let newColumns = [...columns];

    let eventNumberIndex = newColumns.findIndex(
      (obj) => obj.field == "eventNumber"
    );

    if (eventNumberIndex > -1) {
      newColumns[eventNumberIndex].renderCell = (params) => (
        <Button
          variant="text"
          className="event-number-button"
          onClick={() => handleEventNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    }

    setColumns(newColumns);
  };

  const navigateToAddEvent = (isScheduledEvent) => {
    if (isScheduledEvent) {
      props.history.push(`/add-event?isScheduled=true`);
    } else {
      props.history.push(`/add-event`);
    }
  };

  const getTour = () => {
    return (
      <LynxTour
        title={"Welcome to the Events Grid!"}
        description={
          "Events are used to track a variety of data, including community engagement, emissions, inspections and any other types of event based data. The event grid shows your events, and provides a number of options to sort, filter, export and report."
        }
        steps={eventGridTourSteps}
        flagField={"eventsGridTourCompletedDateTimeUtc"}
        tourType={TourTypes.EventsGrid}
      />
    );
  };

  return (
    <>
      <LynxDataGrid
        tour={getTour}
        enableSavedFilters
        columns={columns}
        getDataFunction={getEvents}
        indexDbObjectStoreName={eventStore}
        localStorageName="events"
        addButtonAction={navigateToAddEvent}
        entityName={EntityTypes.Event}
        customFields={eventCustomFields}
        indexDbSortColumn="eventNumber"
        isOfflineReady={isOfflineReady}
        getLookupsFunction={getEventLookups}
        mobileCard={EventMobileCard}
        {...props}
        handleExportAttachments={getEventsAttachments}
        handleEventsExportXLSX={getEventsExportAsXLSX}
        bulkUpdateFunction={bulkUpdateEvents}
        bulkUpdateAttachmentsFunction={bulkUpdateEventAttachments}
        searchEnabled
        searchLabel={
          "Search By: Event #, Category, Subcategory, Description or Tags"
        }
      />
      {!_.isEmpty(complianceValues) && (
        <LynxDialog
          title={_.orderBy(complianceValues).map((value) => (
            <div key={value}>{value}</div>
          ))}
          open={!_.isEmpty(complianceValues)}
          handleConfirm={() => setComplianceValues([])}
          handleClose={() => setComplianceValues([])}
        />
      )}
    </>
  );
}
